import axios from '../../plugins/axios.js';

const defaultSettings = {
  build: '',
  homeUrl: '/',
  cleancodeUrl: '',
  logoUrl: '',
  userData: {},
  isAdmin: false,
  canExportData: false,
  hasAccessAdmin: false,
  hasAccessAdminCompanySettings: false,
  hasAccessAdminUsers: false,
  hasAccessAdminClients: false,
  hasAccessAdminBuildings: false,
  hasAccessAdminAreas: false,
  hasAccessAdminTemplates: false,
  hasAccessAdminRoles: false,
  hasAccessAdminJobAssignments: false,
  hasAccessForms: false,
  hasAccessInspections: false,
  hasAccessCovidVaccine: false,
  hasAccessWorld: false,
  hasAccessQuality: false,
  hasAccessTasks: false,
  hasAccessReports: false,
  hasAccessActionLog: false,
  hasAccessCleanCode: false,
  hasAccessSpecialProjects: false,
  hasAccessLibrary: false,
  hasAccessVipAwards: false,
  hasAccessDashboardInspections: false,
  hasAccessDashboardTasks: false,
  hasAccessDashboardReports: false,
  hasAccessDashboardActionLogs: false,
  hasAccessDashboardSpecialProjects: false,
  canEditFiles: false,
  admin: {
    canAddUser: false,
    canEditUser: false,
    canDeleteUser: false,
    canAddClient: false,
    canDeleteClient: false,
    canAddBuilding: false,
    canDeleteBuilding: false,
    canAddTemplate: false,
    canDeleteTemplate: false,
    canAddArea: false,
    canDeleteArea: false,
    canAddRole: false,
    canDeleteRole: false,
    canAddJobAssignment: false,
    canUpdateJobAssignment: false,
    canEditJobSite: false,
    canEditJobInspections: false,
    canEditJobTenants: false,
    canEditJobContract: false,
    canEditJobClientContacts: false,
    canEditJobLogo: false,
    canEditJobCommonAreas: false,
    canEditJobFloors: false,
    canEditJobSupplies: false,
    canEditJobAssignments: false,
    canEditJobFiles: false,
  },
  actionLogs: {
    canEdit: false,
    canDelete: false,
    canDeleteFinal: false,
    canSendEmail: false,
    onlyFinalized: false,
  },
  clients: {
    canViewClients: false,
  },
  inspections: {
    canAddTask: false,
    canAddTenant: false,
    canDeleteTenant: false,
    canEdit: false,
    canReopen: false,
    canSendEmail: false,
    canFinalize: false,
    canComplete: false,
    canViewAllStatus: false,
    onlyFinalized: false,
  },
  integrations: {
    canView: false,
    canEdit: false,
  },
  reports: {
    canEdit: false,
    canDeleteFinalReport: false,
    canSendEmail: false,
    onlyFinalized: false,
  },
  tasks: {
    canAddTask: false,
    canUpdate: false,
    canEditTransaction: false,
    canDeleteTask: false,
    canAddTaskNote: false,
    canDeleteTaskNote: false,
    canGetNext: false,
  },
  specialProjects: {
    canCreate: false,
    canUpdate: false,
    canDelete: false,
    canTransition: false,
    canAddTenant: false,
    canBulkUpdateTransactions: false,
    canBulkComplete: false,
    canAddScheduler: false,
    canDeleteScheduler: false,
    canAddManager: false,
    canDeleteManager: false,
    onlyActive: false,
    canViewInvoices: false,
    canCreateInvoice: false,
    canUpdateInvoices: false,
    canDeleteInvoices: false,
    canTransitionInvoices: false,
  },
  covidVaccine: {
    canEditRecords: false,
    canDeleteRecords: false,
  },
  library: {
    canAddCourse: false,
    canDeleteCourse: false,
    canAddCourseVideo: false,
    canAddDailyBrief: false,
    canViewLogs: false,
    canAddTopic: false,
    canDeleteTopic: false,
    canViewDailyBriefingStats: false,
  },
  profile: {
    canViewAllBuildings: false,
    canManageCommunicationPreference: false,
  },
  job: {
    canViewAuditLog: false,
  },
};

const state = () => ({
  loaded: false,
  lastSync: 0,
  cacheLifetime: 15 * 60 * 1000, // 15 minutes in milliseconds
  settings: defaultSettings,
});

const getters = {};

const mutations = {
  setLoaded(state, loaded) {
    state.loaded = loaded;
  },
  setSettings(state, payload) {
    if (payload) {
      state.lastSync = new Date().valueOf();
      state.settings = { ...state.settings, ...payload };
    }
  },
  resetSettings(state) {
    state.lastSync = 0;
    state.settings = defaultSettings;
    state.loaded = false;
  },
};

const actions = {
  async loadSettings({ state, commit }) {
    const msSinceLastSync = new Date().valueOf() - state.lastSync;

    // if last sync is within the cache lifetime
    if (state.lastSync && msSinceLastSync < state.cacheLifetime) {
      return;
    }

    // get new settings
    return axios.get('/api/v1/settings').then((response) => {
      commit('setSettings', response.data.payload);
      commit('setLoaded', true);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
