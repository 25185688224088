import axios from '../../plugins/axios.js';

export default {
  namespaced: true,
  state: () => ({
    topicFiles: [],
    courses: [],
  }),
  getters: {
    translatedCourses: (state, getters) => {
      const courses = state.courses.map((c) => ({ ...c }));
      return courses.map(getters.translatedCourse);
    },
    translatedCourse: (state, getters, rootState) => (course) => {
      const lang = rootState.lang;
      const translation = course.translations.find((t) => t.lang === lang);
      if (translation) {
        course.title = translation.title;
        course.description = translation.description;
      }

      return course;
    },
    activeVideo: (state, getters, rootState) => (course) => {
      const lang = rootState.lang;
      let activeVideo = course.active_video.find((v) => v.lang === lang);
      if (!activeVideo) {
        activeVideo = course.active_video.slice(0, 1);
      }

      return activeVideo;
    },
  },
  mutations: {
    topics(state, topics) {
      state.topics = topics;
    },
    topicFiles(state, files) {
      state.topicFiles = files;
    },
    courses(state, courses) {
      state.courses = courses;
    },
  },
  actions: {
    async getTopics() {
      const response = await axios.get('/api/v1/library/topics');
      return response.data.payload;
    },
    async getCourses(ctx) {
      const lang = ctx.rootState.lang;
      const response = await axios.get(`/api/v1/library/courses?lang=${lang}`);
      ctx.commit('courses', response.data.payload);
      return response.data.payload;
    },
    async getCourse({ state, rootState }, courseId) {
      const lang = rootState.lang;
      let course = state.courses.find((c) => c.id === courseId);
      if (!course) {
        const response = await axios.get(`/api/v1/library/course/${courseId}?lang=${lang}`);
        course = response.data.payload;
      }

      return course;
    },
    async getRawCourse({ state }, courseId) {
      let course = state.courses.find((c) => c.id === courseId);
      if (!course) {
        const response = await axios.get(`/api/v1/library/course/${courseId}`);
        course = response.data.payload;
      }

      return course;
    },
    async getCoursePublic({ state, rootState }, { courseId, orgSlug }) {
      let course = state.courses.find((c) => c.id === courseId);
      if (!course) {
        const response = await axios.get(`/api/v1/${orgSlug}/library/course/${courseId}?lang=${rootState.lang}`);
        course = response.data.payload;
      }

      return course;
    },
    async createCourse(ctx, course) {
      const response = await axios.post('/api/v1/library/course', course);
      const newCourse = response.data.payload;
      ctx.commit('courses', [...ctx.state.courses, newCourse]);
      return newCourse;
    },
    async updateCourse(ctx, course) {
      const response = await axios.put(`/api/v1/library/course/${course.id}`, course);
      const newCourse = response.data.payload;

      // refresh course list
      await ctx.dispatch('getCourses');

      return newCourse;
    },
    deleteCourse(ctx, courseId) {
      return axios.delete(`/api/v1/library/course/${courseId}`);
    },
    async addThumbnail(ctx, { courseId, formData }) {
      if (!(formData instanceof FormData)) {
        throw new Error('Invalid FormData object');
      }

      const response = await axios.post(`/api/v1/library/course/${courseId}/thumbnail`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      await ctx.dispatch('getCourses');

      return response.data.payload;
    },
    async addVideo(ctx, { courseId, video }) {
      if (!(video instanceof FormData)) {
        throw new Error('Video must be an instance of FormData when adding a new video to a course.');
      }

      // upload the video
      const response = await axios.post(`/api/v1/library/course/${courseId}/video`, video, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // refresh course list
      await ctx.dispatch('getCourses');

      return response.data.payload;
    },
    async addCourseContent(ctx, { courseId, type, lang, file }) {
      const formData = new FormData();
      formData.set('type', type);
      formData.set('lang', lang);
      formData.set('file', file);

      const response = await axios.post(`/api/v1/library/course/${courseId}/content`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return response.data.payload;
    },
    async deleteCourseContent(ctx, { courseId, contentId }) {
      await axios.delete(`/api/v1/library/course/${courseId}/content/${contentId}`);
    },
  },
};
