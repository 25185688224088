import { createRouter, createWebHistory } from 'vue-router';
import LoginView from '../views/Login.vue';
import ForgotPassword from '../views/ForgotPassword.vue';
import ResetPassword from '../views/ResetPassword.vue';
import store from '../store';
import VerifyAuthenticated from './middleware/VerifyAuthenticated';
import VerifyAuthorized from './middleware/VerifyAuthorized';
import TokenGuard from './middleware/TokenGuard';

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: () => import('../views/MyCoraDashboard.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated],
      title: 'Dashboard',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      hideNav: true,
      title: 'Login',
    },
  },
  {
    path: '/password/forgot',
    component: ForgotPassword,
    meta: {
      hideNav: true,
      title: 'Forgot Password',
    },
  },
  {
    path: '/password/reset',
    component: ResetPassword,
    meta: {
      hideNav: true,
      title: 'Reset Password',
    },
  },
  {
    path: '/signup',
    component: () => import('../views/Signup.vue'),
    meta: {
      hideNav: true,
      title: 'Signup',
    },
  },
  {
    path: '/map/:buildingId?',
    name: 'map',
    component: () => import('../views/world/WorldMap.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.hasAccessWorld,
      title: 'Map',
    },
  },
  {
    path: '/map/details/:buildingId',
    name: 'JobDetails',
    component: () => import('../views/world/BuildingDetails.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.hasAccessWorld,
      title: 'Job Card',
    },
  },
  {
    path: '/audit-log/building/:buildingId',
    props: true,
    component: () => import('../views/audit-logs/BuildingAuditLog.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.hasAccessWorld,
      title: 'Job Audit Log',
    },
  },
  {
    path: '/quality/inspections/dashboard',
    component: () => import('../views/quality/Dashboard.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.hasAccessDashboardInspections,
      title: 'Inspection Dashboard',
    },
  },
  {
    path: '/quality/inspections/create',
    component: () => import('../views/quality/InspectionCreate.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.inspections.canEdit,
      title: 'Create Inspection',
    },
  },
  {
    path: '/quality/inspections',
    component: () => import('../views/quality/Inspections.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.hasAccessInspections,
      title: 'Inspection Report',
    },
  },
  {
    path: '/:orgSlug/inspection',
    component: () => import('../views/quality/PublicInspectionView.vue'),
    meta: {
      showNav: false,
      middleware: [TokenGuard],
      title: 'Inspection',
    },
  },
  {
    path: '/tasks',
    component: () => import('../views/tasks/TaskList.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.hasAccessDashboardTasks,
      title: 'Tasks',
    },
  },
  {
    path: '/tasks/create',
    component: () => import('../views/tasks/TaskCreate.vue'),
    props: {
      redirect: '/tasks',
    },
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.tasks.canAddTask,
      title: 'Create Task',
    },
  },
  {
    path: '/task/:taskId',
    component: () => import('../views/tasks/TaskView.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.hasAccessTasks,
      title: 'Task',
    },
  },
  {
    path: '/:orgSlug/task/:taskId',
    component: () => import('../views/tasks/PublicTaskView.vue'),
    meta: {
      showNav: false,
      middleware: [TokenGuard],
      title: 'Task',
    },
  },
  {
    path: '/task/:taskId/edit',
    component: () => import('../views/tasks/TaskEdit.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.hasAccessTasks,
      title: 'Edit Task',
    },
  },
  {
    path: '/daily-reports/dashboard',
    component: () => import('../views/daily-reports/Dashboard.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.hasAccessDashboardReports,
      title: 'Report Dashboard',
    },
  },
  {
    path: '/daily-reports',
    component: () => import('../views/daily-reports/DailyReports.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.hasAccessReports,
      title: 'Report',
    },
  },
  {
    path: '/special-projects/dashboard',
    component: () => import('../views/special-projects/Dashboard.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.hasAccessDashboardSpecialProjects,
      title: 'Special Projects',
    },
  },
  {
    path: '/special-project/create',
    component: () => import('../views/special-projects/SpecialProjectCreate.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.specialProjects.canCreate,
      title: 'Create Special Projects',
    },
  },
  {
    path: '/special-project/edit/:specialProjectId',
    component: () => import('../views/special-projects/SpecialProjectEdit.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.specialProjects.canUpdate,
      title: 'Edit Special Project',
    },
  },
  {
    path: '/special-project/:specialProjectId/billing',
    component: () => import('../views/special-projects/SpecialProjectInvoiceCreate.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.specialProjects.canCreateInvoice,
      title: 'Create Special Project Invoice',
    },
  },
  {
    path: '/special-project/billing/:invoiceId',
    component: () => import('../views/special-projects/SpecialProjectInvoiceView.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.specialProjects.canViewInvoices,
      title: 'Special Project Invoice',
    },
  },
  {
    path: '/special-project/billing/:invoiceId/edit',
    component: () => import('../views/special-projects/SpecialProjectInvoiceEdit.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.specialProjects.canUpdateInvoices,
      title: 'Edit Special Project Invoice',
    },
  },
  {
    path: '/special-project/:specialProjectId',
    component: () => import('../views/special-projects/SpecialProjectViewV2.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.hasAccessSpecialProjects,
      title: 'Special Project',
    },
  },
  {
    path: '/action-log/dashboard',
    component: () => import('../views/action-log-reports/Dashboard.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.hasAccessDashboardActionLogs,
      title: 'Action Log Dashboard',
    },
  },
  {
    path: '/action-log',
    component: () => import('../views/action-log-reports/ActionLog.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.hasAccessActionLog,
      title: 'Action Log',
    },
  },
  {
    path: '/admin',
    component: () => import('../views/SettingsView.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.hasAccessAdmin,
      title: 'Admin Settings',
    },
  },
  {
    path: '/admin/users',
    component: () => import('../views/admin/Users.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.hasAccessAdminUsers,
      title: 'Admin Users',
    },
  },
  {
    path: '/admin/user/create',
    component: () => import('../views/admin/UserCreate.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.admin.canAddUser,
      title: 'Admin Create User',
    },
  },
  {
    path: '/admin/user/:userId/invite',
    component: () => import('../views/admin/UserInvite.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.admin.canAddUser,
      title: 'Admin User Invite',
    },
  },
  {
    path: '/admin/user/:userId/assignments',
    component: () => import('../views/admin/UserJobAssignments.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.admin.canAddUser,
      title: 'Admin UserJob Assignments',
    },
  },
  {
    path: '/admin/user/:userId',
    component: () => import('../views/admin/UserProfile.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.admin.canAddUser,
      title: 'Admin User Profile',
    },
    props: {
      mode: 'edit',
    },
  },
  {
    path: '/admin/user/:userId/jobs',
    component: () => import('../views/admin/UserJobAccess.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.admin.canAddUser,
      title: 'Admin User Job Access',
    },
  },
  {
    path: '/admin/register',
    component: () => import('../views/admin/UserProfile.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.admin.canAddUser,
      title: 'Admin User Register (old)',
    },
    props: {
      mode: 'register',
    },
  },
  {
    path: '/admin/clients',
    component: () => import('../views/admin/Clients.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.hasAccessAdminClients,
      title: 'Admin Clients',
    },
  },
  {
    path: '/admin/clients/create',
    component: () => import('../views/admin/ClientCreate.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.admin.canAddClient,
      title: 'Admin Client Create',
    },
    props: {
      mode: 'create',
    },
  },
  {
    path: '/admin/clients/edit/:clientId',
    component: () => import('../views/admin/ClientCreate.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.admin.canAddClient,
      title: 'Admin Client Edit',
    },
    props: {
      mode: 'edit',
    },
  },
  {
    path: '/admin/jobs',
    component: () => import('../views/admin/JobSites.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.hasAccessAdminBuildings,
      title: 'Admin Buildings',
    },
  },
  {
    path: '/admin/jobs/create',
    component: () => import('../views/admin/JobSiteCreate.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.admin.canAddBuilding,
    },
  },
  {
    path: '/admin/jobs/:buildingId',
    component: () => import('../views/admin/JobSite.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.hasAccessAdminBuildings,
    },
  },
  {
    path: '/admin/job-assignments',
    component: () => import('../views/admin/JobSiteAssignments.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.hasAccessAdminJobAssignments,
      title: 'Admin Job Assignments',
    },
  },
  {
    path: '/admin/job-assignment/:jobAssignmentId/users',
    component: () => import('../views/admin/JobSiteAssignmentUsers.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.hasAccessAdminJobAssignments,
      title: 'Admin Job Assignment Users',
    },
  },
  {
    path: '/admin/job-assignment/:jobAssignmentId?',
    component: () => import('../views/admin/JobSiteAssignment.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.hasAccessAdminJobAssignments,
      title: 'Admin Job Assignment',
    },
  },
  {
    path: '/admin/templates',
    component: () => import('../views/admin/ContentTemplates.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.hasAccessAdminTemplates,
      title: 'Admin Templates',
    },
  },
  {
    path: '/admin/templates/edit/:templateId',
    component: () => import('../views/admin/CreateTemplate.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.admin.canAddTemplate,
      title: 'Admin Template Edit',
    },
    props: {
      initialMode: 'edit',
    },
  },
  {
    path: '/admin/templates/create',
    component: () => import('../views/admin/CreateTemplate.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.admin.canAddTemplate,
      title: 'Admin Template Create',
    },
    props: {
      initialMode: 'create',
    },
  },
  {
    path: '/admin/areas',
    component: () => import('../views/admin/Areas.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.hasAccessAdminAreas,
      title: 'Admin Areas',
    },
  },
  {
    path: '/admin/areas/edit/:areaId',
    component: () => import('../views/admin/AreasCreate.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.admin.canAddArea,
      title: 'Admin Area Edit',
    },
    props: {
      initialMode: 'edit',
    },
  },
  {
    path: '/admin/areas/create',
    component: () => import('../views/admin/AreasCreate.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.admin.canAddArea,
      title: 'Admin Area Create',
    },
    props: {
      initialMode: 'create',
    },
  },
  {
    path: '/admin/company-settings',
    component: () => import('../views/admin/CompanySettings.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.hasAccessAdminCompanySettings,
      title: 'Admin Company Settings',
    },
  },
  {
    path: '/admin/integrations',
    component: () => import('../views/integrations/IntegrationsList.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.integrations.canView,
      title: 'Admin Integrations',
    },
  },
  {
    path: '/admin/integration/winteam',
    component: () => import('../views/integrations/WinteamIntegration.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.integrations.canView,
      title: 'Winteam Integration',
    },
  },
  {
    path: '/admin/integration/prism',
    component: () => import('../views/integrations/prism/PrismIntegration.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.integrations.canView,
      title: 'Prism Integration',
    },
  },
  {
    path: '/admin/integration/prism/client/:clientId',
    component: () => import('../views/integrations/prism/PrismAccountSettings.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.integrations.canView,
      title: 'Prism Integration',
    },
  },
  {
    path: '/export/buildings',
    component: () => import('../views/BuildingsExport.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.canExportData,
      title: 'Export Building',
    },
  },
  {
    path: '/covid-vaccine-records',
    component: () => import('../views/CovidVaccineRecords.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.hasAccessCovidVaccine,
      title: 'VVR Records',
    },
  },
  {
    path: '/vip-awards',
    component: () => import('../views/VipAwards.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.hasAccessVipAwards,
      title: 'VIP Awards',
    },
  },
  {
    path: '/library',
    component: () => import('../views/library/Library.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated, VerifyAuthorized],
      allowed: store.state.settings.settings.hasAccessLibrary,
      title: 'Library',
    },
    children: [
      {
        path: 'courses',
        component: () => import('../views/library/Courses.vue'),
        meta: {
          showNav: true,
          middleware: [VerifyAuthenticated, VerifyAuthorized],
          title: 'Training Courses',
        },
      },
      {
        path: 'course/create',
        component: () => import('../views/library/CourseCreate.vue'),
        meta: {
          showNav: true,
          allowed: store.state.settings.settings.library.canAddCourse,
          middleware: [VerifyAuthenticated, VerifyAuthorized],
          title: 'Create Library Course',
        },
      },
      {
        path: 'course/:courseId/admin',
        component: () => import('../views/library/CourseAdmin.vue'),
        meta: {
          showNav: true,
          allowed: store.state.settings.settings.library.canAddCourse,
          middleware: [VerifyAuthenticated, VerifyAuthorized],
          title: 'Manage Library Course',
        },
      },
      {
        path: 'course/:courseId/edit',
        component: () => import('../views/library/CourseEdit.vue'),
        meta: {
          showNav: true,
          allowed: store.state.settings.settings.library.canAddCourse,
          middleware: [VerifyAuthenticated, VerifyAuthorized],
          title: 'Edit Library Course',
        },
      },
      {
        path: 'course/:courseId',
        component: () => import('../views/library/Course.vue'),
        meta: {
          showNav: true,
          middleware: [VerifyAuthenticated, VerifyAuthorized],
          title: 'Library Course',
        },
      },
      {
        path: 'course/:courseId/content/create',
        component: () => import('../views/library/CourseContentCreate.vue'),
        meta: {
          showNav: true,
          middleware: [VerifyAuthenticated, VerifyAuthorized],
          title: 'Library Course: Add Content',
        },
      },
      {
        path: 'course/:courseId/logs',
        component: () => import('../views/library/CourseVideoLogs.vue'),
        meta: {
          showNav: true,
          middleware: [VerifyAuthenticated, VerifyAuthorized],
          title: 'Library Course Video Stats',
        },
      },
    ],
  },
  {
    path: '/:orgSlug/library/daily-briefing-video',
    redirect: (to) => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return { path: `/${to.params.orgSlug}/library/courses` };
    },
  },
  {
    path: '/:orgSlug/library/courses',
    component: () => import('../views/library/CoursesPublic.vue'),
    meta: {
      hideNav: true,
      middleware: [TokenGuard],
      title: 'Training Videos',
    },
  },
  {
    path: '/:orgSlug/library/course/:courseId',
    component: () => import('../views/library/CoursePublic.vue'),
    meta: {
      hideNav: true,
      middleware: [TokenGuard],
      title: 'Training Video',
    },
  },
  {
    path: '/forms/vip-award',
    component: () => import('../views/forms/VipAwardForm.vue'),
    meta: {
      hideNav: true,
      middleware: [TokenGuard],
      title: 'VIP Award Form',
    },
  },
  {
    path: '/forms/covid-vaccine',
    component: () => import('../views/forms/CovidVaccine.vue'),
    meta: {
      hideNav: true,
      middleware: [TokenGuard],
      title: 'VVR Form',
    },
  },
  {
    path: '/forms',
    component: () => import('../views/Forms.vue'),
    meta: {
      showNav: true,
      title: 'Forms',
    },
  },
  {
    path: '/covid-vaccine-policy',
    component: () => import('../views/CovidVaccinePolicy.vue'),
    meta: {
      hideNav: true,
      title: 'VVR Policy',
    },
  },
  {
    path: '/forms/confirmation',
    component: () => import('../views/FormConfirmation.vue'),
    meta: {
      hideNav: true,
      title: 'Form Confirmation',
    },
  },
  {
    path: '/profile',
    component: () => import('../views/Profile.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated],
      title: 'User Profile',
    },
  },
  {
    path: '/profile/:userId',
    component: () => import('../views/UserProfile.vue'),
    meta: {
      showNav: true,
      middleware: [VerifyAuthenticated],
      title: 'User Profile',
    },
  },
  {
    path: '/not-found',
    component: () => import('../views/NotFound.vue'),
    meta: {
      title: 'Not Found',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/not-found',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from) => {
  // service worker check update
  window.wb?.update();

  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];

    const context = {
      to,
      from,
      router,
    };

    // call each middleware and check for failed result
    for (let i = 0; i < middleware.length; i++) {
      const result = await middleware[i](context);
      // a result that is false or a route object means the middleware failed and navigation should stop
      if (result === false || typeof result === 'object') {
        console.log('stop navigation', result, i, middleware);
        return result;
      }
    }
  }

  await store.commit('breadcrumbs/setBreadcrumbs', []);
});

router.afterEach((to) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
});

export default router;
