<template>
  <div>
    <v-navigation-drawer v-model="primaryDrawer.model">
      <router-link to="/">
        <v-list-item class="px-12 py-6">
          <v-img
            v-if="organization.logo_horizontal"
            cover
            :src="`${organization.logo_horizontal.url}#${organization.logo_horizontal.md5}`"
            :alt="organization.name"
          />
          <v-img v-else src="/images/cora.svg" alt="cora logo" max-height="40px" cover />
        </v-list-item>
      </router-link>
      <v-sheet :color="organization?.primary_brand_color ?? 'accent'" height="50" />

      <div class="nav-wrapper">
        <div class="avatar text-center">
          <v-menu>
            <template #activator="{ props }">
              <v-btn size="x-small" icon v-bind="props">
                <v-icon>mdi-cog-outline</v-icon>
              </v-btn>
            </template>

            <v-list>
              <template v-for="item in settingsItems()">
                <v-list-item
                  v-if="item.isAllowed"
                  :key="item.text"
                  :to="item.to"
                  :href="item.href"
                  class="primary-nav-text"
                >
                  <template #prepend>
                    <img v-if="item.iconUrl" :src="`/images/icons/${item.iconUrl}`" alt="" />
                    <v-icon v-else color="secondary">{{ item.icon }}</v-icon>
                  </template>

                  <v-list-item-title>{{ $t(item.text).capitalize() }}</v-list-item-title>
                </v-list-item>
              </template>
              <v-list-item class="primary-nav-text" @click="logout">
                <template #prepend>
                  <v-icon class="text-orange">mdi-logout</v-icon>
                </template>
                <v-list-item-title>{{ $t('logout').capitalize() }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <profile-avatar
            class="mx-6"
            :avatar-size="70"
            border-size="3px"
            :img-condition="!!user.profile_photo_id"
            :img-source="user.profile_photo_url"
            :profile-user-name="user.name"
          />

          <v-btn
            size="x-small"
            icon
            :color="organization?.secondary_brand_color ?? 'black'"
            :to="settings.hasAccessAdminCompanySettings ? '/admin/company-settings' : null"
          >
            <v-avatar v-if="organization?.logo?.id" size="32">
              <v-img cover :src="`${organization.logo?.url}#${organization.logo?.md5}`" :alt="organization.name" />
            </v-avatar>

            <v-icon v-else color="white">mdi-office-building-cog-outline</v-icon>
          </v-btn>
        </div>

        <v-list-item class="text-center">
          <v-list-item-title>{{ user.name?.capitalize() }}</v-list-item-title>
          <v-list-item-subtitle>{{ user.title?.capitalize() }}</v-list-item-subtitle>
        </v-list-item>

        <v-list>
          <template v-for="item in menuItems()">
            <v-list-item
              v-if="item.isAllowed"
              :key="item.text"
              :to="item.to"
              :href="item.href"
              class="primary-nav-text"
              density="default"
              :target="item.target || '_self'"
            >
              <v-list-item-title class="d-flex align-center ml-7">
                <img :src="`/images/icons/${item.iconUrl}`" alt="" height="25px" />
                <span class="text-margin"> {{ $t(item.text).capitalize() }} </span>
              </v-list-item-title>

              <template v-if="item.appendIcon" #append>
                <v-icon size="small" class="text-grey">{{ item.appendIcon }}</v-icon>
              </template>
            </v-list-item>
          </template>
        </v-list>
      </div>

      <template #append>
        <div class="legal-copy">
          <div class="d-flex flex-column text-center">
            <span class="text-caption">&copy; {{ new Date().getFullYear() }} Cora Technologies</span>
            <span class="text-caption">{{ settings.build }}</span>
          </div>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar flat style="border-bottom: 1px solid rgba(0, 0, 0, 0.2)">
      <template #prepend>
        <v-app-bar-nav-icon class="d-lg-none" @click.stop="primaryDrawer.model = !primaryDrawer.model" />
      </template>

      <v-autocomplete
        v-if="user.license !== 'viewer'"
        v-model="selectedBuilding"
        :items="buildings"
        item-value="id"
        item-title="name_formal"
        density="compact"
        variant="outlined"
        rounded
        clearable
        :placeholder="$t('job sites')"
        class="pt-7 ml-4"
        @update:model-value="goToInfoCard"
      >
        <template #prepend-inner>
          <i class="large material-icons">search</i>
        </template>
      </v-autocomplete>

      <v-spacer />

      <span class="mr-1">
        <offline-indicator />
      </span>

      <span>
        <locale />
      </span>

      <template v-if="Array.isArray(breadcrumbs) && breadcrumbs.length > 0" #extension>
        <v-breadcrumbs class="pl-0" :items="breadcrumbs" color="accent" />
      </template>
    </v-app-bar>
  </div>
</template>

<script>
import axios from '../plugins/axios';
import router from '../router';
import { mapGetters, mapState } from 'vuex';
import ProfileAvatar from './core/ProfileAvatar.vue';
import Locale from './Locale.vue';
import OfflineIndicator from './OfflineIndicator.vue';

export default {
  components: { Locale, OfflineIndicator, ProfileAvatar },
  data() {
    return {
      selectedBuilding: null,
      mapCardUrl: '/map/details/',
      primaryDrawer: {
        model: null,
        type: 'default (no property)',
      },
    };
  },
  computed: {
    ...mapState({
      settings: (state) => state.settings.settings,
      user: (state) => state.user.user,
      organization: (state) => state.organization.organization,
      log: (state) => state.breadcrumbs.log,
      buildings: (state) => state.buildings.buildings,
    }),
    ...mapGetters({
      breadcrumbs: 'breadcrumbs/breadcrumbs',
    }),
  },
  mounted() {
    if (this.user.id) {
      let user = { ...this.user };
      user.super_admin = user.super_admin === 1;
      user.admin = this.settings.isAdmin;
      this.getProfilePhoto(user);
    }
    this.getBuildings();
  },
  methods: {
    logout() {
      this.$store.dispatch('user/logout');
    },
    getProfilePhoto(user) {
      axios.get(`/api/v1/core/user/profile-photo-id/${user.id}`).then((response) => {
        if (response.data?.payload) {
          let profile_photo_id = response.data.payload.id;
          this.$store.commit('user/setUser', {
            ...user,
            profile_photo_id: profile_photo_id,
            profile_photo_url: response.data.payload.url,
          });
        }
      });
    },
    menuItems() {
      return [
        { text: 'MyCora', to: '/', iconUrl: 'dashboard-icon.svg', isAllowed: true },
        { text: 'map', to: '/map', iconUrl: 'world-icon.svg', isAllowed: this.settings.hasAccessWorld },
        {
          text: 'inspections',
          to: '/quality/inspections/dashboard',
          iconUrl: 'inspections-icon.svg',
          isAllowed: this.settings.hasAccessQuality,
        },
        { text: 'tasks', to: '/tasks', iconUrl: 'tasks-icon.svg', isAllowed: this.settings.hasAccessTasks },
        {
          text: 'reports',
          to: '/daily-reports/dashboard',
          iconUrl: 'reports-icon.svg',
          isAllowed: this.settings.hasAccessReports,
        },
        {
          text: 'cleancode',
          href: this.settings.cleancodeUrl,
          iconUrl: 'cleancode-icon.svg',
          isAllowed: this.settings.hasAccessCleanCode,
          appendIcon: 'mdi-open-in-new',
          target: '_blank',
        },
        {
          text: 'action logs',
          to: '/action-log/dashboard',
          iconUrl: 'actions-icon.svg',
          isAllowed: this.settings.hasAccessActionLog,
        },
        {
          text: 'special projects',
          to: '/special-projects/dashboard',
          iconUrl: 'special-projects-icon.svg',
          isAllowed: this.settings.hasAccessSpecialProjects,
        },
        {
          text: 'learning',
          to: '/library/courses',
          iconUrl: 'uni-icon.svg',
          isAllowed: this.settings.hasAccessLibrary,
        },
      ];
    },
    settingsItems() {
      return [
        { text: 'profile', to: '/profile', icon: 'mdi-account', isAllowed: true },
        { text: 'settings', to: '/admin', icon: 'mdi-cog', isAllowed: this.settings.isAdmin },
        {
          text: 'integrations',
          to: '/admin/integrations',
          icon: 'mdi-transit-connection-horizontal',
          isAllowed: this.settings.isAdmin,
        },
        { text: 'export', to: '/export/buildings', icon: 'mdi-download', isAllowed: this.settings.canExportData },
        {
          text: 'forms',
          to: '/forms',
          icon: 'mdi-clipboard-text-outline',
          isAllowed: this.settings.hasAccessForms,
        },
        // {
        //   text: 'vaccine tracker',
        //   to: '/covid-vaccine-records',
        //   icon: 'mdi-clipboard-text-outline',
        //   isAllowed: this.settings.hasAccessCovidVaccine,
        // },
        // {
        //   text: 'vip awards',
        //   to: '/vip-awards',
        //   icon: 'mdi-clipboard-text-outline',
        //   isAllowed: this.settings.hasAccessVipAwards,
        // },
      ];
    },
    getBuildings() {
      this.$store.dispatch('buildings/get');
    },
    goToInfoCard(id) {
      //Open a separate window traveling to the info card
      // window.open(this.mapCardUrl + id, '_blank');
      //Redirect but on same page
      if (id) {
        this.selectedBuilding = null;
        router.push(this.mapCardUrl + id);
      }
    },
  },
};
</script>
<style scoped>
.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-wrapper {
  position: relative;
  top: -35px;
}

.text-margin {
  margin-left: 14px;
}

.theme--light.v-list-item:hover::before,
.v-list-item--active {
  opacity: unset;
  background-color: rgba(255, 144, 1, 0.06);
  border-left: 6px solid orange;
}

.v-list--nav .v-list-item,
.v-list--nav .v-list-item:before {
  border-radius: unset;
}

.v-list-item--link:before {
  background-color: unset;
  margin-left: -8px;
  margin-right: -8px;
  opacity: unset;
}

.v-application .text-caption {
  opacity: 0.4;
}

.legal-copy {
  margin-bottom: 20px;
}

[role='combobox'] {
  box-shadow: inset 0 1px 1px #d7d7d7;
}
</style>
